<template>

    <voffice-popup-box :trigger-id="triggerId"
        class="full-xxs"
        :apply-label="applyLabel"
        @closed="onClosed">

        <table class="guestbox">

            <tr v-for="(l,what) in sections"
                class="gb-row">

                <td class="gb-td label">
                    <label>{{getFieldLabel(what)}}</label>
                    <span v-if="what === 'children'"><br><small>bis 17 Jahre</small></span>
                    <span v-if="what === 'babys'"><br><small>bis 2 Jahre</small></span>
                </td>
                <td class="gb-td">

                    <button class="btn btn-default"
                        :disabled="!canDecr(what)"
                        @click="inc(what,-1)"
                        type="button">-</button>

                </td>
                <td class="gb-td">

                    <button class="btn btn-default"
                        :disabled="!canInc(what)"
                        @click="inc(what,1)"
                        type="button">+</button>


                </td>
            </tr>

        </table>


    </voffice-popup-box>



</template>

<script>
    import vofficePopupBox from './PopupBox.vue';
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'voffice-guestbox',
    	components: {
    		vofficePopupBox
    	},
    	voMsgCodes: [
    		'pet.pl',
    		'adult.pl',
    		'child.pl',
    		'baby.pl'
    	],
    	props: {
    		maxValues: Object,
    		minValues: Object,
    		searchData: Object,
    		showDogCountInSearch: Boolean,
    		triggerId: String,
    		applyLabel: String,
    		childrenWelcome: Boolean,
    		babiesWelcome: {
    			type: Boolean,
    			default: true
    		},
    		petsWelcome: Boolean,
    		beds: Number,
    		bedsForKids: Number,
    		from: {
    			type: String,
    			default: 'gb'
    		}
    	},
    	computed: {
    		sections: function() {
    			var res = {
    				'adults': 'adult'
    			};
    			if (this.childrenWelcome) {
    				Object.assign(res, {
    					'children': 'child',
    				});
    			}
    			/*	if (this.babiesWelcome) {
    					Object.assign(res, {
    						'babys': 'baby'
    					});
    				} */
    			if (this.petsWelcome) {
    				res.petsCount = 'pet';
    			}

    			return res;

    		}
    	},
    	methods: {
    		onClosed: function() {
    			this.$emit('closed');
    		},

    		checkPersons: function(what) {

    			if (what === 'adults' && this.bedsForKids && parseInt(this.bedsForKids) > 0) {
    				var maxBeds = parseInt(this.beds) - parseInt(this.bedsForKids);
    				if (parseInt(this.searchData.adults) >= maxBeds) {
    					return false;
    				} else {
    					return true;
    				}
    			}
    			if (parseInt(this.searchData['adults']) + parseInt(this.searchData['children']) + parseInt(this.searchData['babys']) >= this.beds) {
    				return false;
    			} else {
    				return true;
    			}
    		},


    		getFieldLabel: function(what) {
    			var amount = this.searchData[what] || 0;
    			return amount + ' ' + this.voMsgPl(this.sections[what] + '.pl', amount);
    		},
    		inc: function(what, step) {
    			this.searchData[what] = ((this.searchData[what] || 0) - 0) + step;
    			if (what == 'petsCount') {
    				this.searchData.pets = this.searchData[what] > 0;
    			}
    			//console.log('what:::' + what);
    			if (what == 'children') {
    				if (this.searchData[what] == 0 && $('.nxtbutton')) {
    					$('.nxtbutton').prop('disabled', false);
    				}
    			}

    		},
    		canInc: function(what) {
    			var amount = ((this.searchData[what] || 0) - 0);
    			var max = this.maxValues && this.maxValues[what];
    			//console.log("amout==>" + amount);
    			//console.log("what==>" + what);
    			if ((this.from == 'quickbooker' || this.from == 'mobbooking') && what == 'petsCount' && max && parseInt(max) >= 0) {
    				max = parseInt(max) + 1
    			}
    			if (what === 'petsCount' && max != undefined && amount == max) {
    				//console.log("inside condition==>" + $('#' + this.getMessageId()));
    				$('#' + this.getMessageId()).removeClass("d-none");
    				//$('#petsmsg').css('display', 'block');
    			}

    			if (max != undefined && amount >= max) {
    				return false;
    			}
    			if (what === 'adults' || what === 'children') {
    				if (this.beds && parseInt(this.beds) > 0) {
    					return this.checkPersons(what);
    				}
    			}
    			return true;
    		},
    		canDecr: function(what) {
    			//console.log('canDescr:::::::::::::::::::::::::::::::');
    			var amount = ((this.searchData[what] || 0) - 0);
    			var max = this.maxValues && this.maxValues[what];
    			if (what === 'petsCount' && max != undefined && amount < max) {
    				//console.log('adding class d-none.......................');
    				$('#' + this.getMessageId()).addClass("d-none");
    			}
    			var min = this.minValues && this.minValues[what];
    			if (min == undefined) {
    				min = what == 'adults' ? 1 : 0
    			}

    			if (min != undefined && amount <= min) {
    				return false;
    			}
    			return true;
    		},

    	}

    };
</script>

<style scoped>
    .guestbox {
    	margin: 0 auto;
    	line-height: 12px;
    	text-align: left;
    	font-size: 15px;
    	color: #676767;
    }


    .gb-td {
    	padding: 8px 12px;
    }

    .btn {
    	width: 48px;
    	height: 48px;
    	line-height: 1;
    	border-radius: 50%;
    	font-size: 30px;
    	padding: 4px;
    }

    .label {
    	white-space: nowrap;
    }

    @media (max-width:768px) {}
</style>